import React from "react"
import { Link } from "gatsby"
import Moment from "react-moment"

const CaseStudy = ({ caseStudies }) => {
  return (
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-10 p-10 md:p-0">
      {caseStudies.map((caseStudy, i) => {
        return (
          <Link to={`/case-study/${caseStudy.node.slug}`} className="">
            <div className="">
              <div className="relative">
                <img
                  src={caseStudy.node.headerImage.url}
                  alt={caseStudy.node.headerImage.url}
                  height="100"
                  className="rounded-lg border-0 border-gray-200 w-full h-48 object-cover"
                />
                <div className="bg-white absolute top-0 left-0 m-5 p-2 rounded-lg w-32">
                  <img src={caseStudy.node.logo.url} alt="" className="" />
                </div>
              </div>
              <div className="">
                <div className="flex justify-between text-sm text-gray-400 mt-5">
                  <Moment format="MMM Do YYYY">{caseStudy.node.updatedAt}</Moment>
                  <span className="bg-orange-500 text-xs text-white px-2 py-1 rounded-lg capitalize">
                    {caseStudy.node.case_study_category.Name}
                  </span>
                </div>
                <p className="font-bold text-gray-800 text-2xl leading-tight mt-2">
                  {caseStudy.node.heading}
                </p>
              </div>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default CaseStudy
