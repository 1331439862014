import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const CaseStudyFilter = () => (
  <div className="px-10 md:px-0">

    <StaticQuery
      query={graphql`
        query {
          allStrapiCaseStudyCategory {
            edges {
              node {
                Name
              }
            }
          }
        }
      `}
      render={data => (
        <div className="flex text-sm mt-5">
          <p className="mr-2 text-gray-400">Filter by: </p>

          <Link to={`/case-studies`} className="mr-3 capitalize text-orange-500 underline">
            All
          </Link>

          {data.allStrapiCaseStudyCategory.edges.map((filter, i) => (
            <Link to={`/case-studies/${filter.node.Name}`} className="mr-3 capitalize text-orange-500 underline">
              {filter.node.Name}
            </Link>
          ))}
          
        </div>
      )}
    />

  </div>
)

export default CaseStudyFilter