import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import CaseStudy from "../components/case_study"
import CaseStudyFilter from "../components/case_study_filter"

import Seo from "../components/seo"
import Footer from "../components/footer"

import wave from "../images/wave.svg"

export const query = graphql`
  query Category($id: String!) {
    caseStudies: allStrapiCaseStudy(filter: {case_study_category: {id: {eq: $id}}}) {
      edges {
        node {
          strapiId
          heading
          slug
          updatedAt
          headerImage {
            url
          }
          logo {
            url
          }
          case_study_category {
            id
            Name
          }
        }
      }
    }
  }
`

const Category = ({ data }) => {
  // const caseStudies = data.caseStudies.edges

  return (
    <Layout>
      <Seo
      title="Case Studies"
      description="Case studies"
    />

    <div className="md:mt-12">

      <section id="top" className="w-full md:px-10 relative">
        <div className="container mx-auto py-5 border-b border-gray-200 md:mb-10">
          <header className="flex flex-col-reverse md:flex-row w-full top-0 p-10 md:p-0">
            <div className="w-full md:mt-12 md:mt-0">
              <div className="text-center md:text-left">
                <h1 className="text-3xl md:text-5xl text-gray-700 font-bold leading-tight tracking-tight animated fadeIn">Flow <span className="wrap">Case Studies</span></h1>
                <p className="inline-block text-md md:text-xl font-light text-gray-400 mx-auto mt-3">See how our agency, developer and brand partner clients are growing their business with Flow.</p>
              </div>
            </div>
          </header>

          <CaseStudyFilter />

        </div>        
      </section>

      <CaseStudy caseStudies={data.caseStudies.edges} />

    </div>

    <img className="w-full mt-10" src={wave} alt="" />
    <Footer />

    </Layout>
  )
}

export default Category
